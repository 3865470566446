import { QueryClient } from '@tanstack/react-query';

export const CacheKeys = {
  getAllFeedbackResults: 'getAllFeedbackResults',
  fetchSpacesList: 'fetchSpacesList',
  getSpaceRuntime: 'getSpaceRuntime',
  fetchParticipantInfo: 'fetchParticipantInfo',
  getOrganization: 'getOrganization',
  getBreakoutSessions: 'getBreakoutSessions',
  fetchFeedbackTemplates: 'fetchFeedbackTemplates',
  getSpaceTemplatesOfFeedbacksTemplates: 'getSpaceTemplatesOfFeedbacksTemplates',
  fetchActiveTemplateFeedback: 'fetchActiveTemplateFeedback',
  fetchFeedbackTemplate: 'fetchFeedbackTemplate',
  getParticipantsInParticipantSelectComponent: 'getParticipantsInParticipantSelectComponent',
  getFeedbackResponseSummary: 'getFeedbackResponseSummary',
  getAllFeedbackResponseSummary: 'getAllFeedbackResponseSummary',
  fetchMaterials: 'fetchMaterials',
  fetchParticipants: 'fetchParticipants',
  fetchSpaceThemeById: 'fetchSpaceThemeById',
  getSpaceById: 'getSpaceById',
  fetchSpaceTemplates: 'fetchSpaceTemplates',
  fetchSpaceAcessInfo: 'fetchSpaceAcessInfo',
  fetchRuntimeMaterials: 'fetchRuntimeMaterials',
  getSpaceAccessToken: 'getSpaceAccessToken',
  fetchAccessibleUsers: 'fetchAccessibleUsers',
  getOrganizationById: 'getOrganizationById',
  getOrganizationPublicInfo: 'getOrganizationPublicInfo',
  fetchSpaceInvitation: 'fetchSpaceInvitation',
  getResourceForViewer: 'getResourceForViewer',
  getResourcesForViewer: 'getResourcesForViewer',
  fetchAllChannels: 'fetchAllChannels',
  fetchChecklistActivities: 'fetchChecklistActivities',
  createSpaceInvitation: 'createSpaceInvitation',
  getAgendaTemplates: 'getAgendaTemplates',
  getPreAsignBreakoutSessions: 'getPreAsignBreakoutSessions',
  fetchUsedSpaceTemplates: 'fetchUsedSpaceTemplates',
  fetchFeedbackResponse: 'fetchFeedbackResponse',
  fetchOrgInsightsSummary: 'fetchOrgInsightsSummary',
  getDataInsight: 'getDataInsight',
  fetchOverall: 'fetchOverall',
  fetchSpace: 'fetchSpace',
  fetchParticipantsSummary: 'fetchParticipantsSummary',
  fetchSpaceUsersBasicInfo: 'fetchSpaceUsersBasicInfo',
  fetchEngagementSummary: 'fetchEngagementSummary',
  fetchDetailsTableEngagement: 'fetchDetailsTableEngagement',
  fetchParticipantVisitDetails: 'fetchParticipantVisitDetails',
  fetchUserTrafficDetails: 'fetchUserTrafficDetails',
  fetchResourceInteractionSummary: 'fetchResourceInteractionSummary',
  fetchResourceInteractionsResources: 'fetchResourceInteractionsResources',
  fetchResourceInteractionsUsers: 'fetchResourceInteractionsUsers',
  getIntegrationConnections: 'getIntegrationConnections',
  getUserIntegrationConnections: 'getUserIntegrationConnections',
  fetchTopUser: 'fetchTopUser',
  getChallenge: 'getChallenge',
  fetchMessageTemplate: 'fetchMessageTemplate',
  getBreakoutRooms: 'getBreakoutRooms',
  getBreakoutRoomsSimple: 'getBreakoutRoomsSimple',
  fetchUserChallenge: 'fetchUserChallenge',
  getScaleResults: 'getScaleResults',
  getSliderResults: 'getSliderResults',
  getGridResults: 'getGridResults',
  getWordCloudResults: 'getWordCloudResults',
  getPollResults: 'getPollResults',
  fetchChallengeResults: 'fetchChallengeResults',
  fetchComments: 'fetchComments',
  getExternalUserInfo: 'getExternalUserInfo',
  fetchSpaceUsers: 'fetchSpaceUsers',
  getJoinedParticipants: 'getJoinedParticipants',
  getWhiteBoardSettings: 'getWhiteBoardSettings',
  fetchRuntimeConferenceByCode: 'fetchRuntimeConferenceByCode',
  getSpaceUser: 'getSpaceUser',
  createRuntimeToken: 'createRuntimeToken',
  getContributors: 'getContributors',
  fetchSpaceThemes: 'fetchSpaceThemes',
  fetchEmailTemplates: 'fetchEmailTemplates',
  getOrganizationUsers: 'getOrganizationUsers',
  getOrganizationUser: 'getOrganizationUser',
  getNumberOfUsersLimit: 'getNumberOfUsersLimit',
  getRecordings: 'getRecordings',
  fetchSharedWithMeRecordings: 'fetchSharedWithMeRecordings',
  getSpacesForFiltering: 'getSpacesForFiltering',
  getHostsForFiltering: 'getHostsForFiltering',
  getOrganizationsUsers: 'getOrganizationsUsers',
  fetchSpacesTemplate: 'fetchSpacesTemplate',
  fetchShareSpaceMessageTemplate: 'fetchShareSpaceMessageTemplate',
  fetchNotificationSettings: 'fetchNotificationSettings',
  fetchUserNotifications: 'fetchUserNotifications',
  fetchUserNewMessages: 'fetchUserNewMessages',
  fetchNotificationsBadges: 'fetchNotificationsBadges',
  getNotificationsBadgesBySpaceId: 'getNotificationsBadgesBySpaceId',
  getParticipantsInSpace: 'getParticipantsInSpace',
  getMaterialResource: 'getMaterialResource',
  requestGoogleFontsList: 'requestGoogleFontsList',
  fetchFonts: 'fetchFonts',
  getSpaceCmsDirectory: 'getSpaceCmsDirectory',
  getSpaceMiscDirectory: 'getSpaceMiscDirectory',
  getSpaceExtraDirectory: 'getSpaceExtraDirectory',
  fetchNotificationSettingForParticipants: 'fetchNotificationSettingForParticipants',
  fetchSpaceNotificationSettings: 'fetchSpaceNotificationSettings',
  getUnsplashPhotos: 'getUnsplashPhotos',
  getRuntimeIntegrationConnections: 'getRuntimeIntegrationConnections',
  fetchChannelRequest: 'fetchChannelRequest',
  fetchNotificationSettingOfParticipants: 'fetchNotificationSettingOfParticipants',
  fetchQuestionChallengeAnswer: 'fetchQuestionChallengeAnswer',
  getVeevaToken: 'getVeevaToken',
  getVaamThumbnailUrls: 'getVaamThumbnailUrls',
  getPublicSpaceAccessToken: 'getPublicSpaceAccessToken',
  getGlobalResourcesMngt: 'getGlobalResourcesMngt',
  searchGlobalResources: 'searchGlobalResources',
  getVimeoVideoThumbnailUrl: 'getVimeoVideoThumbnailUrl',
  getDocumentThumbnailUrl: 'getDocumentThumbnailUrl',
  getResourceThumbnail: 'getResourceThumbnail',
  fetchInsightsVisitor: 'fetchInsightsVisitor',
  fetchResourceInteractionsSummaryPublicSpaces: 'fetchResourceInteractionsSummaryPublicSpaces',
  fetchTopPerformingResourcesPublicSpace: 'fetchTopPerformingResourcesPublicSpace',
  getOneDriveResources: 'getOneDriveResources',
  getOneDriveThumbnailUrl: 'getOneDriveThumbnailUrl',
  getExternalResource: 'getExternalResource',
  searchOneDriveResources: 'searchOneDriveResources',
  getUserInfo: 'getUserInfo',
  getIsAuthenticated: 'getIsAuthenticated',
  checkExternalLinkEbeddabled: 'checkExternalLinkEbeddabled',
  checkExistingSlugQuery: 'checkExistingSlugQuery',
  checkExitingDomainQuery: 'checkExitingDomainQuery',
  checkExitingTagNameQuery: 'checkExitingTagNameQuery',
  getResourceInfo: 'getResourceInfo',
  fetchChecklistCategories: 'fetchChecklistCategories',
  getGoogleDriveResources: 'getGoogleDriveResources',
  searchGoogleDriveResources: 'searchGoogleDriveResources',
  getSpaceDetailsQuery: 'getSpaceDetailsQuery',
  fetchRequestAccessById: 'fetchRequestAccessById',
  fetchRequestsAccess: 'fetchRequestsAccess',
  fetchAllSpaceUsers: 'fetchAllSpaceUsers',
  getSpaceFeatureTemplate: 'getSpaceFeatureTemplate',
  getFeatureFlags: 'getFeatureFlags',
  getSpaceUsers: 'getSpaceUsers',
  fetchRecordedFile: 'fetchRecordedFile',
  fetchAllSpaceResources: 'fetchAllSpaceResources',
  getCreatedFromTemplateSpaces: 'getCreatedFromTemplateSpaces',
  getCreatedFromTemplateSpacesCount: 'getCreatedFromTemplateSpacesCount',
  getSpaceChangesCount: 'getSpaceChangesCount',
  spaceAnalytics_FetchResourcesSummary: 'spaceAnalytics_FetchResourcesSummary',
  spaceAnalytics_FetchResourcesMetrics: 'spaceAnalytics_FetchResourcesMetrics',
  spaceAnalytics_FetchUserTraffic: 'spaceAnalytics_FetchUserTraffic',
  spaceAnalytics_FetchSpaceChatSummary: 'spaceAnalytics_FetchSpaceChatSummary',
  spaceAnalytics_FetchSpaceChatDetail: 'spaceAnalytics_FetchSpaceChatDetail',
  spaceAnalytics_FetchSpaceChatsByUser: 'spaceAnalytics_FetchSpaceChatsByUser',
  spaceAnalytics_FetchSpaceVideoMessageById: 'spaceAnalytics_FetchSpaceVideoMessagesById',
  spaceAnalytics_FetchSpaceVideoMessageSummary: 'spaceAnalytics_FetchSpaceVideoMessagesSummary',
  spaceAnalytics_FetchSpaceEngagementByUser: 'spaceAnalytics_fetchSpaceEngagementByUser',
  spaceAnalytics_FetchUsersByAssessmentById: 'spaceAnalytics_FetchUsersByAssessmentById',
  spaceAnalytics_FetchSpaceEngagementSummaryByUser:
    'spaceAnalytics_FetchSpaceEngagementSummaryByUser',
  spaceAnalytics_FetchSpaceResourcesSummaryByUser:
    'spaceAnalytics_FetchSpaceResourcesSummaryByUser',
  spaceAnalytics_fetchUserTraffic: 'spaceAnalytics_fetchUserTraffic',
  spaceAnalytics_FetchSpaceResourcesSummaryById: 'spaceAnalytics_FetchSpaceResourcesSummaryById',
  spaceAnalytics_fetchAllCmsResources: 'spaceAnalytics_fetchAllCmsResources',
  spaceAnalytics_FetchSpaceAssessmentSummary: 'spaceAnalytics_FetchSpaceAssessmentSummary',
  spaceAnalytics_FetchSpaceAssessmentsByUser: 'spaceAnalytics_FetchSpaceAssessmentsByUser',
  spaceAnalytics_FetchAssessmentsUserSummary: 'spaceAnalytics_FetchAssessmentsUserSummary',
  createSpaceAccessibility: 'createSpaceAccessibility',
  companyAnalytics_getTagsOrganization: 'companyAnalytics_getTagsOrganization',
  companyAnalytics_getOrganizationSummary: 'companyAnalytics_getOrganizationSummary',
  companyAnalytics_getOrganizationInsights: 'companyAnalytics_getOrganizationInsights',
  companyAnalytics_getMasterDataByTagIds: 'companyAnalytics_getMasterDataByTagIds',
  fetchSpaceAnalyticsSummary: 'fetchSpaceAnalyticsSummary',
  getOneDriveResourceId: 'getOneDriveResourceId',
  getGoogleDriveResourceId: 'getGoogleDriveResourceId',
  getSpaceMaterials: 'getSpaceMaterials',
  subscriptionOrganization: 'subscriptionOrganization',
  fetchTagsOrganization: 'fetchTagsOrganization',
  getDriveFolderInfo: 'getDriveFolderInfo',
  getResourceUsageStats: 'getResourceUsageStats',
  getCadToken: 'getCadToken',
  getFolderNavigations: 'getFolderNavigations',
  fetchSpacesOfUser: 'fetchSpacesOfUser',
  getSpaceAccessibility: 'getSpaceAccessibility',
  shareSpaceCode: 'shareSpaceCode',
  fetchAssessments: 'fetchAssessments',
  fetchAssessment: 'fetchAssessment',
  fetchSpaceAssessments: 'fetchSpaceAssessments',
  fetchSpaceAssessment: 'fetchSpaceAssessment',
  fetchUserSpaceAssessment: 'fetchUserSpaceAssessment',
  fetchBasicSpacesList: 'fetchBasicSpacesList',
  assessments_FetchSpaceUserCertificates: 'assessments_fetchSpaceUserCertificates',
  assessments_GetCertificateFile: 'assessments_getCertificateFile',
};

const queryCache = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
    },
  },
});

export const clearCache = (queryKey, exact = true) => {
  queryCache.invalidateQueries({ queryKey: queryKey, exact: exact });
};

export default queryCache;
