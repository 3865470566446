import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import _ from 'lodash';
import { createExternalConnectionIfNotExists } from '../../resources/ResourceServices';
import { parseDateTimeStringFromServer, formatDateTime } from '../../commons/DateTimeUtils';
import useFlatDirectories from '../../components/Material/useFlatDirectories';
import useResourceNotifications from '../../components/Material/useResourceNotifications';
import currentOrganization from '../../commons/CurrentOrganization';
import { getFolderPath, getMaterialsByDirectory } from '../../commons/ResourceUtils';
import eventBus, { EVENT_BUS } from '../../commons/EventBus';
import { OrganizationUserRole } from '../../app/appConstants';
import { isOrgRole } from '../../commons/utils';
import useSpaceMaterialsQuery from './useSpaceMaterialsQuery';
import spaceUser from '../spaceUser';
import AssessmentCertificatesHandler from './AssessmentCertificatesHandler';

const MaterialsContext = React.createContext({});

MaterialsContext.displayName = 'MaterialsContext';

const getDescriptionDirectory = memoizeOne(({ created, lastModified }) => {
  const displayDate = parseDateTimeStringFromServer(lastModified || created)?.local();
  return `${formatDateTime(displayDate, 'll')}`;
});

export const buildTreeDirectories = memoizeOne((flatDirectories) => {
  if (!flatDirectories) return [];
  let newDirectories = [];
  // let totalResources = 0;
  // let storageSize = 0;

  flatDirectories.forEach((directory) => {
    // description
    const description = getDescriptionDirectory(directory);

    // totalResources += directory.spaceResources.length;
    // directory.spaceResources.forEach((material) => {
    //   if (material.fileSize) {
    //     storageSize += material.fileSize;
    //   }
    // });
    const spaceResources = getMaterialsByDirectory(directory);

    Object.assign(directory, {
      lastModified: directory.lastModified || directory.created,
      description,
      spaceResources,
      isFolder: true,
      subFolders: directory.subFolders ?? [],
    });

    if (!directory.parentFolderId) {
      newDirectories.push(directory);
    } else {
      const parentFolder = flatDirectories.find((item) => item.id === directory.parentFolderId);
      if (parentFolder) {
        if (!parentFolder.subFolders) {
          parentFolder.subFolders = [];
        }
        const indexFolder = parentFolder.subFolders.findIndex((item) => item.id === directory.id);
        if (indexFolder < 0) {
          parentFolder.subFolders.push(directory);
        } else {
          parentFolder.subFolders[indexFolder] = directory;
        }
        Object.assign(directory, { parentFolder });
        Object.assign(directory, { paths: getFolderPath(directory) });
        if (parentFolder.externalFolderSettings && !directory.externalFolderSettings) {
          Object.assign(directory, {
            externalFolderSettings: {
              provider: parentFolder.externalFolderSettings.provider,
            },
          });
        }
      }
    }
  });
  newDirectories = _.orderBy(newDirectories, ['desc']);
  // storageSize = formatBytes(storageSize);

  return { newDirectories, totalResources: 0, storageSize: 0 };
});

export const useMaterialsContext = () => React.useContext(MaterialsContext);

const getMaterialsContext = memoizeOne(
  (
    getMaterialsQuery,
    flatDirectories,
    processingResources,
    selectedFileId,
    selectedDirectoryId,
    openForm,
    parentFolderId,
    viewMaterial,
    isFetchingMaterials
  ) => {
    const { newDirectories, totalResources, storageSize } = buildTreeDirectories(flatDirectories);
    const currentUser = {
      isLoggedIn:
        spaceUser?.hasLoggedIn() || spaceUser?.isSupervisor() || currentOrganization.isAdmin(),
      isHost: spaceUser?.isHost() || currentOrganization.isAdmin(),
      isViewOnlyMode: spaceUser?.isViewOnly(),
      userInfoId: spaceUser?.getUserInfoId(),
      isPartner: isOrgRole(spaceUser?.getOrgRole(), OrganizationUserRole.Partner),
      isVisitor: spaceUser?.isVisitor(),
    };

    return {
      getMaterialsQuery,
      flatDirectories,
      directories: newDirectories,
      resources: newDirectories,
      totalResources,
      storageSize,
      processingResources,
      currentUser,
      selectedFileId,
      selectedDirectoryId,
      openForm,
      parentFolderId,
      viewMaterial,
      isFetchingMaterials,
    };
  }
);

const MaterialsContextProvider = ({ children, spaceId, isPortal, viewMaterial }) => {
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [selectedDirectoryId, setSelectedDirectoryId] = useState(null);
  const [parentFolderId, setParentFolderId] = useState(null);
  const [openForm, setOpenForm] = useState(null);

  const getMaterialsQuery = useSpaceMaterialsQuery(spaceId, isPortal, false, false);
  const refetchMaterials = getMaterialsQuery.refetch;

  const {
    flatDirectories,
    onMaterialUpdated,
    onMaterialDeleted,
    onConversionFinished,
    onTranscodingFinished,
    onMaterialAdded,
    onFolderAdded,
    onFolderUpdated,
    onFolderDeleted,
    onConvertToPDF,
    onReConversion,
    onConversionStarted,
    onConversionFailed,
    processingResources,
    onMaterialMoved,
    onDirectoryMoved,
    setFlatDirectories,
    updateExternalFolder,
  } = useFlatDirectories(getMaterialsQuery, spaceUser, isPortal);

  const { mergeFlatDirectoriesWithNotifications } = useResourceNotifications(flatDirectories);

  const context = getMaterialsContext(
    getMaterialsQuery,
    flatDirectories,
    processingResources,
    selectedFileId,
    selectedDirectoryId,
    openForm,
    parentFolderId,
    viewMaterial
  );

  const deleteMaterial = useCallback(
    (directoryId, materialId, callback) => {
      const detail = { directoryId, materialId };
      onMaterialDeleted(detail);
      if (callback) {
        callback();
      }
    },
    [onMaterialDeleted]
  );

  const onShareFolderUpdated = useCallback(
    (detail) => {
      if (detail.spaceId === spaceId) {
        refetchMaterials();
      }
    },
    [refetchMaterials, spaceId]
  );

  const handleAddedFolder = useCallback(
    (detail) => {
      console.log('@@@@onfolder materialContext ');
      if (detail.spaceId === spaceId) {
        onFolderAdded(detail);
      }
    },
    [onFolderAdded, spaceId]
  );

  const onFolderUploaded = useCallback(
    (detail) => {
      console.log('@@@@onFolderUploaded materialContext ', detail);
      if (detail.createdBy !== spaceUser?.id) {
        refetchMaterials();
      }
    },
    [refetchMaterials]
  );

  const onResourcesUpdatedFromTemplate = useCallback(
    (detail) => {
      console.log('### 161 onResourcesUpdatedFromTemplate: ', detail);
      if (detail?.spaceId === spaceId) {
        refetchMaterials();
      }
    },
    [refetchMaterials, spaceId]
  );

  const onMaterialsCopied = useCallback(
    (detail) => {
      console.log('### 263 onMaterialsCopied: ', detail);
      const id = detail?.spaceId || detail?.spaceId;
      if (id === spaceId) {
        refetchMaterials();
      }
    },
    [refetchMaterials, spaceId]
  );

  const onUserCredentialCompleted = useCallback(() => {
    refetchMaterials();
  }, [refetchMaterials]);

  useEffect(() => {
    const unsubscribes = [];
    unsubscribes.push(eventBus.subscribe(EVENT_BUS.MaterialAdded, onMaterialAdded));
    unsubscribes.push(eventBus.subscribe(EVENT_BUS.MaterialUpdated, onMaterialUpdated));
    unsubscribes.push(eventBus.subscribe(EVENT_BUS.MaterialDeleted, onMaterialDeleted));
    unsubscribes.push(eventBus.subscribe(EVENT_BUS.ConversionFinished, onConversionFinished));
    unsubscribes.push(eventBus.subscribe(EVENT_BUS.TranscodingFinished, onTranscodingFinished));
    unsubscribes.push(eventBus.subscribe(EVENT_BUS.DirectoryAdded, handleAddedFolder));
    unsubscribes.push(eventBus.subscribe(EVENT_BUS.DirectoryUpdated, onFolderUpdated));
    unsubscribes.push(eventBus.subscribe(EVENT_BUS.SharedFolderUpdated, onShareFolderUpdated));
    unsubscribes.push(eventBus.subscribe(EVENT_BUS.DirectoryDeleted, onFolderDeleted));
    unsubscribes.push(eventBus.subscribe(EVENT_BUS.ConversionStarted, onConversionStarted));
    unsubscribes.push(eventBus.subscribe(EVENT_BUS.ConversionFailed, onConversionFailed));
    unsubscribes.push(eventBus.subscribe(EVENT_BUS.DirectoryMoved, onDirectoryMoved));
    unsubscribes.push(eventBus.subscribe(EVENT_BUS.MaterialMoved, onMaterialMoved));
    unsubscribes.push(eventBus.subscribe(EVENT_BUS.DirectoryUploaded, onFolderUploaded));
    unsubscribes.push(
      eventBus.subscribe(EVENT_BUS.MaterialsUpdatedFromTemplates, onResourcesUpdatedFromTemplate)
    );
    unsubscribes.push(eventBus.subscribe(EVENT_BUS.MaterialsCopied, onMaterialsCopied));
    unsubscribes.push(
      eventBus.subscribe(EVENT_BUS.UserCredentialCompleted, onUserCredentialCompleted)
    );

    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe());
    };
  }, [
    onMaterialAdded,
    onMaterialUpdated,
    onMaterialDeleted,
    onConversionFinished,
    onTranscodingFinished,
    handleAddedFolder,
    onFolderUpdated,
    onShareFolderUpdated,
    onFolderDeleted,
    onConversionStarted,
    onConversionFailed,
    onDirectoryMoved,
    onMaterialMoved,
    onFolderUploaded,
    onResourcesUpdatedFromTemplate,
    onMaterialsCopied,
    onUserCredentialCompleted,
  ]);

  context.reset = useCallback(() => {
    setSelectedDirectoryId(null);
  }, []);
  context.deleteMaterial = deleteMaterial;
  context.onConvertToPDF = onConvertToPDF;
  context.onReConversion = onReConversion;
  context.setSelectedFileId = setSelectedFileId;
  context.setSelectedDirectoryId = setSelectedDirectoryId;
  context.setParentFolderId = setParentFolderId;
  context.updateExternalFolder = updateExternalFolder;

  context.handleOpenForm = useCallback((type, id, folderId, parentId = null) => {
    setOpenForm(type);
    if (type === 'file' || type === 'fileSetting') {
      setSelectedFileId(id);
      setSelectedDirectoryId(folderId);
    } else if (type === 'folder' || type === 'uploadFolder') {
      setSelectedFileId(null);
      setSelectedDirectoryId(id);
    }
    if (parentId) {
      setParentFolderId(parentId);
    }
  }, []);

  context.handleCloseForm = useCallback(() => {
    setOpenForm(null);
    setSelectedFileId(null);
    setSelectedDirectoryId(null);
  }, []);

  context.refetchMaterials = refetchMaterials;
  context.setFlatDirectories = setFlatDirectories;

  context.getResourceIdAndMaterialIdExternalFile = useCallback(
    async (material, folderId) => {
      if (!material.id) {
        const data = {
          directoryId: folderId,
          name: material.name,
          externalId: material.externalId,
          type: material.type,
          provider: material.provider,
          src: material.src,
        };
        const resp = await createExternalConnectionIfNotExists(spaceId, data, isPortal);

        Object.assign(material, { id: resp.materialId, resourceId: resp.resourceId });
        if (!material.spaceId) {
          Object.assign(material, { spaceId: spaceId });
        }
      }
      return material;
    },
    [isPortal, spaceId]
  );

  mergeFlatDirectoriesWithNotifications();

  return (
    <MaterialsContext.Provider value={context}>
      <AssessmentCertificatesHandler />
      {children}
    </MaterialsContext.Provider>
  );
};

MaterialsContextProvider.propTypes = {
  children: PropTypes.element,
  spaceId: PropTypes.string,
  isPortal: PropTypes.bool,
  viewMaterial: PropTypes.func,
};

export default MaterialsContextProvider;
