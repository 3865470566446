import { useQuery } from '@tanstack/react-query';
import { fetchSpaceAssessments } from '../services';
import { CacheKeys } from '../../app/queryCache';

function useSpaceAssessmentsQuery(ids, isPortal, enabled = true) {
  const userSpaceAssessments = useQuery({
    queryKey: [CacheKeys.fetchSpaceAssessments, ids, isPortal],
    queryFn: async () => {
      const resp = await fetchSpaceAssessments(ids, isPortal);
      return resp;
    },
    enabled: enabled,
  });

  return {
    userSpaceAssessments,
    isLoading: userSpaceAssessments.isLoading,
    spaceAssessments: userSpaceAssessments.data,
    refetch: userSpaceAssessments.refetch,
  };
}

export default useSpaceAssessmentsQuery;
