import React from 'react';
import { getAllAssessments } from '../../cms/cmsUtils';
import { useSpaceContext } from '../SpaceContext';
import { useMaterialsContext } from './MaterialsContextProvider';
import useAssessmentCertificatesQuery from './useAssessmentCertificatesQuery';
import { DirectoryType, ResourceType } from '../../app/appConstants';

const AssessmentCertificatesHandler = () => {
  const { space } = useSpaceContext();
  const allAssessments = getAllAssessments(space);
  const { flatDirectories, setFlatDirectories } = useMaterialsContext();
  const { data } = useAssessmentCertificatesQuery(allAssessments?.length > 0);

  React.useEffect(() => {
    if (!!data && data.length > 0 && !!flatDirectories) {
      const newFlatDirectories = [...flatDirectories];
      const rootFolderId = 'my-certificates';
      const rootFolder = {
        id: rootFolderId,
        name: 'Certificates',
        parentFolder: null,
        subFolders: [],
        isFolder: true,
        directoryType: DirectoryType.Default,
      };
      data.forEach((assessment) => {
        const assessmentFolder = {
          id: assessment.assessmentId,
          name: assessment.assessmentName,
          parentFolderId: rootFolderId,
          spaceResources: [],
          isFolder: true,
        };
        assessment.userCertificates.forEach((certificate) => {
          const certificateResource = {
            userCredentialId: certificate.userCredentialId,
            name: `${certificate.name}.pdf`,
            displayName: `${certificate.name}.pdf`,
            created: certificate.completedOn,
            lastModified: certificate.completedOn,
            date: certificate.completedOn,
            extension: 'pdf',
            type: ResourceType.pdf,
            isLms: true,
            spaceId: space.id,
          };
          assessmentFolder.spaceResources.push(certificateResource);
        });
        rootFolder.subFolders.push(assessmentFolder);
        newFlatDirectories.push(assessmentFolder);
      });

      // Check if the certFolder already exists to prevent unnecessary updates
      const certFolderExists = newFlatDirectories.some((folder) => folder.id === rootFolderId);

      if (!certFolderExists) {
        newFlatDirectories.push(rootFolder);
        console.log('new newFlatDirectories', newFlatDirectories);
        setFlatDirectories(newFlatDirectories);
      }
    }
  }, [flatDirectories, data, setFlatDirectories, space.id]);
  return null;
};

export default AssessmentCertificatesHandler;
